// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  display: block; }

.menu-container {
  margin: 0 auto;
  max-width: 700px;
  padding: 0 1rem;
  text-align: center; }
  .menu-container button {
    background: white;
    border: 1px solid black;
    border-radius: 2px;
    color: black;
    display: block;
    font-family: Lexend, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    height: 40px;
    margin: 1.5rem auto 0;
    padding: 0;
    width: 312px; }
    .menu-container button:active, .menu-container button:hover {
      background: black;
      color: white; }
  .menu-container h2 {
    color: black;
    font-family: Lexend, sans-serif;
    font-weight: 500;
    margin-bottom: 2.5rem; }

@media screen and (orientation: portrait) {
  .menu-container {
    max-width: 500px; } }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/Menu.scss"],"names":[],"mappings":"AAAA;EACE,cAAc,EAAA;;AAGhB;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,kBAAkB,EAAA;EAJpB;IAOI,iBAAiB;IACjB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,cAAc;IACd,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,qBAAqB;IACrB,UAAU;IACV,YAAY,EAAA;IAlBhB;MAqBM,iBAAiB;MACjB,YAAY,EAAA;EAtBlB;IA2BI,YAAY;IACZ,+BAA+B;IAC/B,gBAAgB;IAChB,qBAAqB,EAAA;;AAIzB;EACE;IACE,gBAAgB,EAAA,EACjB","sourcesContent":[".menu {\n  display: block;\n}\n\n.menu-container {\n  margin: 0 auto;\n  max-width: 700px;\n  padding: 0 1rem;\n  text-align: center;\n\n  button {\n    background: white;\n    border: 1px solid black;\n    border-radius: 2px;\n    color: black;\n    display: block;\n    font-family: Lexend, sans-serif;\n    font-size: 1rem;\n    font-weight: bold;\n    height: 40px;\n    margin: 1.5rem auto 0;\n    padding: 0;\n    width: 312px;\n\n    &:active, &:hover {\n      background: black;\n      color: white;\n    }\n  }\n\n  h2 {\n    color: black;\n    font-family: Lexend, sans-serif;\n    font-weight: 500;\n    margin-bottom: 2.5rem;\n  }\n}\n\n@media screen and (orientation: portrait) {\n  .menu-container {\n    max-width: 500px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
