// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-play-container {
  background-color: white;
  display: flex;
  height: 100%;
  overflow-y: auto; }

.audio-transcript .role-play-container {
  width: 89%; }

@media (min-width: 992px) and (orientation: landscape) {
  .role-play .role-play-container {
    flex: 0 0 auto;
    max-width: 44%;
    max-width: calc(100vh - 7rem - 4.3vw - 64px); } }

@media screen and (orientation: portrait) {
  .audio-transcript .role-play-container {
    width: 75%; } }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/RolePlay.scss"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,YAAY;EACZ,gBAAgB,EAAA;;AAGlB;EACE,UAAU,EAAA;;AAGZ;EACE;IACI,cAAc;IACd,cAAc;IACd,4CAA4C,EAAA,EAC/C;;AAGH;EACE;IACE,UAAU,EAAA,EACX","sourcesContent":[".role-play-container {\n  background-color: white;\n  display: flex;\n  height: 100%;\n  overflow-y: auto;\n}\n\n.audio-transcript .role-play-container {\n  width: 89%;\n}\n\n@media (min-width: 992px) and (orientation: landscape) {\n  .role-play .role-play-container {\n      flex: 0 0 auto;\n      max-width: 44%;\n      max-width: calc(100vh - 7rem - 4.3vw - 64px);\n  }\n}\n\n@media screen and (orientation: portrait) {\n  .audio-transcript .role-play-container {\n    width: 75%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
