import React, { useRef } from 'react';
import '../stylesheets/Overlay.scss';

const Overlay = ({ isOpen, onClose, headerTitle, children }) => {
  const overlayRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (overlayRef.current && !overlayRef.current.contains(e.target)) {
      onClose(e);
    }
    e.stopPropagation();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="overlay" onClick={handleOutsideClick}>
        <div className='overlay-box-container'>
          <div className="overlay-header">
            <div className="spacing-div"></div>
            <h4>{headerTitle ? headerTitle : ''}</h4>
            <button className="close-btn" onClick={onClose}>
                <span className="close-icon">
                  <span>close</span>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.4587 0.265753C12.813 -0.0885843 13.3799 -0.0885843 13.7342 0.265753C14.0886 0.62009 14.0886 1.18696 13.7342 1.54133L8.27774 6.99783L13.7342 12.472C14.0886 12.8263 14.0886 13.3932 13.7342 13.7475C13.3799 14.0842 12.813 14.0842 12.4587 13.7475L7.00217 8.27341L1.52803 13.7475C1.1737 14.0842 0.606829 14.0842 0.252461 13.7475C-0.0841538 13.3932 -0.0841538 12.8263 0.252461 12.472L5.72659 6.99783L0.252461 1.54133C-0.0841538 1.18699 -0.0841538 0.62012 0.252461 0.265753C0.606799 -0.0885843 1.17367 -0.0885843 1.52803 0.265753L7.00217 5.73989L12.4587 0.265753Z" />
                  </svg>
                </span>
            </button>
          </div>
          <div className="overlay-content" ref={overlayRef}>
            {children}
          </div>
        </div>
    </div>
  );
};

export default Overlay;