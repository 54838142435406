// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-modal-overlay {
  align-items: center;
  background-color: rgba(52, 55, 57, 0.75);
  height: 100%;
  inset: 0;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 1010; }
  .app-modal-overlay.visible {
    display: flex; }

.app-modal {
  background-color: #353739;
  border-radius: 1.25rem;
  width: 308px; }
  .app-modal span {
    color: white;
    width: 100%;
    padding: 3rem 2rem; }
  .app-modal > div {
    border-top: 2px #1a1b1c solid;
    width: 100%; }
  .app-modal button {
    color: #52a2dd;
    background: none;
    border: none;
    flex: 1 1;
    height: 100%;
    padding: 1rem;
    transition: text-shadow 250ms, font-weight 250ms; }
    .app-modal button:hover {
      text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.25);
      font-weight: 700; }
    .app-modal button:not(:first-child) {
      border-left: 2px #1a1b1c solid; }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/Modal.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,wCAAwC;EACxC,YAAY;EACZ,QAAQ;EACR,uBAAuB;EACvB,eAAe;EACf,WAAW;EACX,aAAa,EAAA;EARf;IAWI,aAAa,EAAA;;AAIjB;EACE,yBAAyB;EACzB,sBAAsB;EACtB,YAAY,EAAA;EAHd;IAMI,YAAY;IACZ,WAAW;IACX,kBAAkB,EAAA;EARtB;IAWI,6BAA6B;IAC7B,WAAW,EAAA;EAZf;IAgBI,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,SAAO;IACP,YAAY;IACZ,aAAa;IACb,gDAAgD,EAAA;IAtBpD;MAyBM,kDAAgD;MAChD,gBAAgB,EAAA;IA1BtB;MA6BM,8BAA8B,EAAA","sourcesContent":[".app-modal-overlay {\n  align-items: center;\n  background-color: rgba(52, 55, 57, 0.75);\n  height: 100%;\n  inset: 0;\n  justify-content: center;\n  position: fixed;\n  width: 100%;\n  z-index: 1010;\n\n  &.visible {\n    display: flex;\n  }\n}\n\n.app-modal {\n  background-color: #353739;\n  border-radius: 1.25rem;\n  width: 308px;\n\n  span {\n    color: white;\n    width: 100%;\n    padding: 3rem 2rem;\n  }\n  > div {\n    border-top: 2px #1a1b1c solid;\n    width: 100%;\n  }\n\n  button {\n    color: #52a2dd;\n    background: none;\n    border: none;\n    flex: 1;\n    height: 100%;\n    padding: 1rem;\n    transition: text-shadow 250ms, font-weight 250ms;\n\n    &:hover {\n      text-shadow: 0px 0px 3px rgba(255,255,255, 0.25);\n      font-weight: 700;\n    }\n    &:not(:first-child) {\n      border-left: 2px #1a1b1c solid;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
