exports.splashContactLink = 'mailto:hello@theapexagency.com';

exports.wakeWords = ["hey coach"];
exports.wakeWordDisplay = "Hey Coach";
exports.emptyTranscribeResponse = 'Sorry, could you repeat that?';

exports.stopRecordingDelay = 1500; // 1.5 seconds
exports.maxAudioRecording = 90000;

// Replacement words
exports.replaceList = [
   ["f-150", "F150"],
   ["EQS", "E QS"],
   ["Ioniq 6's", "Ioniq 6"],
   ["lb", "pound"],
   ["lb.", "pound"],
   ["lbs", "pounds"],
   ["lbs.", "pounds"],
   ["lb-ft", "pound feet"],
   ["lb.-ft.", "pound feet"],
   ["ft3", "feet cubed"],
   ["cu. ft.", "cubic feet"],
   ["ft", "feet"],
   ["mi", "miles"],
   ["hp", "horse power"],
   ["sec", "seconds"],
   ["sec.", "seconds"],
   ["min", "minutes"],
   ["min.", "minutes"],
   ["hrs", "hours"],
   ["mi", "miles"],
   ["awd", "all wheel drive"],
   ["rwd", "rear wheel drive"],
   ["vdc", "volts of direct current"],
   ["kg", "kilogram"],
   ["kgs", "kilograms"],
   ["yrs", "years"],
   ["mph", "miles per hour"],
   ["kph", "kilometers per hour"]
 ];

 // What we can't replace
//  ["in", "inches"] => would replace all instances of the word "in"

// Prompt Tester
exports.promptTesterModId = "664a8bb685f669fb97714e53";

// Session summary pages
exports.summaryRangeStart = '2024-07-15';
exports.summaryRangeEnd = '2024-07-31';