import React, {useCallback, useEffect, useRef, useState} from 'react';
import './App.scss';

import {Route, Routes} from "react-router-dom";
import {getLocal, setLocal} from "./utils/dataFetching";

import Menu from "./components/general/Menu";
import Landing from "./components/general/Landing";
import Evaluation from './components/general/Evaluation';
import PromptTest from './components/general/PromptTest';
import {useAuth} from "./context/AuthContext";
import RolePlay from "./components/featureset/RolePlay";
import KnowledgeAssistant from './components/featureset/KnowledgeAssistant';
import Tester from "./components/general/Tester";
import VoiceTest from './components/general/VoiceTest';
import SummaryModules from './components/general/SummaryModules';
import Summaries from './components/general/Summaries';
import ViewLog from './components/general/ViewLog';
import Splash from './components/general/Splash';

const queryParams = new URLSearchParams(window.location.search);
const ccode = queryParams.get('key') || getLocal('ccode');
const datestart = queryParams.get('datestart');
const dateend = queryParams.get('dateend');

const nonDemoUrls = [
  'new',
  'prompt-test',
  'view-log',
  'voice-test'
];

window.onpopstate = () => {
  window.location.reload();
};

window.onpageshow = (e) => {
  if (e.persisted) {
    window.location.reload();
  }
};

function App() {
  document.title = 'AI Coach';

  const { isAuthenticated } = useAuth();
  //const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { login } = useAuth();

  const [demo, setDemo] = useState(getLocal('demo'));

  const navigate = useCallback ((p) => {
    // This currently fixes the issues encountered by switching between modes
    //    the speech recognition service doesn't get unloaded (commented out bc it was causing other issues)
    window.location = p;
  },[]);

  const attemptLogin = useCallback(async (ccode) => {
    //console.log(submitted);
    if (!submitted) {
      setSubmitted(true);
      console.log("");
      console.log('attempting Login', ccode);

      try {
        let loginResult = await login(ccode);
        console.log(loginResult);

        if (loginResult === 'wait') {
          //window.location.reload();
          return;
        }
        if (loginResult.status === 200) {
          //handleLoginSuccess(ccode);
        } else if (loginResult.status === 'error') {
          console.log('Invalid key');
        } else {
          console.log(loginResult && !loginResult.success ? loginResult.message : "An unexpected error occurred.");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        //setTimeout(() => setSubmitted(false),2000);
      }
    }
  }, [login, submitted]);

  useEffect(() => {
    const attempt = async () => {
      console.log('effect',ccode,isAuthenticated, submitted);

      if (ccode && !isAuthenticated && !submitted) {
        console.log(isAuthenticated, submitted);
        await attemptLogin(ccode);
      }
    }

    attempt();
  }, [ attemptLogin, submitted, isAuthenticated]);

  const audioRef = useRef(null);
  const [audioPlayed, setAudioPlayed] = useState(false);

  const getDemoSlug = useCallback (() => {
    const p = window.location.pathname.split('/');
    return p.length > 0 ? p[1] : '';
  }, []);

  const getStep = useCallback (() => {
    const p = window.location.pathname.split('/');
    return (p.length > 0 ? p[p.length - 1] : '');
  }, []);

  const [step, setStep] = useState(getStep());

  const handleLogout = useCallback((e) => {
    e?.preventDefault();
    // localStorage.clear();
    // sessionStorage.clear();
    setLocal('auth_token','');
    setLocal('user','');
    setLocal('session_id','');
    navigate('/');
    setStep('');
  },[navigate]);

  const handleAppOnClick = useCallback(() => {
    if (!audioPlayed) {
      setAudioPlayed(true);
      if (!audioRef.current.paused && !audioRef.current.currentTime) {
        audioRef.current.src = '/speech.mp3';
        audioRef.current.play();
      }
    }
  },[audioPlayed]);

  useEffect(() => {
    //console.log('use EFFECT');
    async function fetchToken() {
      try {
        const token = getLocal("auth_token");
        if (!token) {
          // const API_TOKEN = await getToken();
          // localStorage.setItem("auth_token", API_TOKEN);
        }
      } catch (error) {}
    }

    fetchToken();

    return () => {
      //console.log('removing auth token');
      //localStorage.removeItem("auth_token");
    };
  }, []);

  useEffect(() => {
    // console.log('use EFFECT1');
    const currentSeg = getStep();

    if (step && step !== currentSeg) {
      //console.log('use EFFECT2', step, currentSeg);
      //navigate('/' + step);
      return;
    }

    if (isAuthenticated) {
      if (demo && demo.slug) {
        if (currentSeg !== 'menu' && !nonDemoUrls.includes(currentSeg) && !demo?.assistants?.find(a => a.slug === currentSeg) && !demo?.scenarios?.find(s => s.slug === currentSeg || s.slug + '-evaluation' === currentSeg || s.slug + '-landing' === currentSeg || window.location.pathname.includes('/' + s.slug + '-evaluation/'))) {
          //console.log('use EFFECT3A');
          
          setStep('menu');
          navigate('/' + demo.slug + '/menu');
          return;
        }
      }
    } else {
      if (currentSeg !== '' && currentSeg !== 'new') {
        //console.log('use EFFECT4');

        setStep('');
        navigate('/');
        return;
      }
    }

  }, [demo, getDemoSlug, getStep, isAuthenticated, navigate, step, handleLogout ]);

  useEffect(() => {
    const slug = demo?.slug || 'default';

    document.documentElement.style.setProperty('--event-logo-image', `url('../assets/${slug}/images/event-logo.png')`);
    document.documentElement.style.setProperty('--landscape-bg-image', `url('../assets/${slug}/images/bg/landscape.png')`);
    document.documentElement.style.setProperty('--portrait-bg-image', `url('../assets/${slug}/images/bg/portrait.png')`);
  
    document.documentElement.style.setProperty('--default-audio-control-bg-image', `url('../assets/${slug}/images/audio/ready.png')`);
    document.documentElement.style.setProperty('--recording-audio-control-bg-image', `url('../assets/${slug}/images/audio/ready.png')`);
    document.documentElement.style.setProperty('--processing-audio-control-bg-image', `url('../assets/${slug}/images/audio/processing.gif')`);
    document.documentElement.style.setProperty('--listening-audio-control-bg-image', `url('../assets/${slug}/images/audio/listening.gif')`);
  
    document.documentElement.style.setProperty('--transcript-switch-mode-bg-image', `url('../assets/${slug}/images/avatar.png')`);
    document.documentElement.style.setProperty('--avatar-switch-mode-bg-image', `url('../assets/${slug}/images/transcript.png')`);
  
    document.documentElement.style.setProperty('--processing-role-play-overlay-bg-image', `url('../assets/${slug}/images/avatar/processing.gif')`);
    document.documentElement.style.setProperty('--recording-role-play-overlay-bg-image', `url('../assets/${slug}/images/avatar/recording.gif')`);
  
    document.documentElement.style.setProperty('--end-button-bg-image', `url('../assets/${slug}/images/end.svg')`);
    document.documentElement.style.setProperty('--end-button-active-bg-image', `url('../assets/${slug}/images/end-active.svg')`);
    document.documentElement.style.setProperty('--refresh-button-bg-image', `url('../assets/${slug}/images/refresh.png')`);
    document.documentElement.style.setProperty('--talk-button-bg-image', `url('../assets/${slug}/images/talk.svg')`);
    document.documentElement.style.setProperty('--talking-button-bg-image', `url('../assets/${slug}/images/talk-active.svg')`);
    document.documentElement.style.setProperty('--wait-button-bg-image', `url('../assets/${slug}/images/talk-wait.svg')`);
  
    document.documentElement.style.setProperty('--knowledge-base-iphone-bg-image', `url('../assets/${slug}/images/bg/assistant-iphone.png')`);
    document.documentElement.style.setProperty('--knowledge-base-landscape-bg-image', `url('../assets/${slug}/images/bg/assistant-landscape.png')`);
    document.documentElement.style.setProperty('--knowledge-base-portrait-bg-image', `url('../assets/${slug}/images/bg/assistant-portrait.png')`);
  }, [demo]);

  const getClassNames = () => {
    const step = getStep();
    let classNames = [];

    classNames.push('col-12');
    classNames.push('App');

    if (demo?.assistants?.find(a => a.slug === step)) {
      classNames.push('knowledge-assistant');
    } else if (demo?.scenarios?.find(s => s.slug === step)) {
      classNames.push('role-play');
    } else if (demo?.scenarios?.find(s => s.slug + '-evaluation' === step || window.location.pathname.includes('/' + s.slug + '-evaluation/'))) {
      classNames.push('role-play-evaluation');
    } else if (demo?.scenarios?.find(s => s.slug + '-landing' === step)) {
      classNames.push('role-play-landing');
    } else if (step) {
      classNames.push(step);
    } else {
      classNames.push('splash');
    }

    if (audioPlayed) {
      classNames.push('audio-enabled');
    }

    return classNames.join(' ');
  };

  return (
    <div className={getClassNames()} onClick={handleAppOnClick}>
      {/*<Header handleLogout={handleLogout} isAuthenticated={isAuthenticated} />*/}
      {isAuthenticated ? (
        <Routes>
          <Route path="/" element={<Splash />} />
          <Route path="/new" element={<Tester />} />
          <Route path="/prompt-test" element={<PromptTest />} />
          <Route path="/view-log" element={<ViewLog />} />
          <Route path="/voice-test" element={<VoiceTest />} />

          <Route path={'/' + demo.slug + '/menu'} element={<Menu />} />

          {demo?.scenarios && demo.scenarios.map(s => {
            return (
              <React.Fragment key={s._id}>
                <Route path={'/' + demo.slug + '/' + s.slug + '-landing'} element={<Landing scenario={s} />} />
                <Route path={'/' + demo.slug + '/' + s.slug} element={<RolePlay scenario={s} audioRef={audioRef} />} />
                <Route path={'/' + demo.slug + '/' + s.slug + '-evaluation/*'} element={<Evaluation scenario={s} />} />
              </React.Fragment>
            );
          })}
          
          <Route path="/session-summaries" element={<SummaryModules datestart={datestart} dateend={dateend} />} />
          <Route path="/session-summaries/*" element={<Summaries datestart={datestart} dateend={dateend} />} />

          {demo?.assistants && demo.assistants.map(a => {
            return (
              <Route key={a._id} path={'/' + demo.slug + '/' + a.slug} element={<KnowledgeAssistant assistant={a} audioRef={audioRef} audioPlayed={audioPlayed} />} />
            );
          })}
        </Routes>
      ) : (
        <Routes>
          {/*<Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />*/}
          <Route path="/" element={<Splash />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/new" element={<Tester />} />
        </Routes>
      )}
      <audio ref={audioRef} autoPlay src='/speech.mp3' crossOrigin="anonymous" />
    </div>
  );
}

export default App;
