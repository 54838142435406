import '../stylesheets/PromptTest.scss';
import {getLLMResponse, getModule, getAssistantModules, getScenarioModules, getSummaryModules} from "../../utils/fetch/llmFetching";
import {sessionCreate, sessionList} from "../../utils/session/session";
import {promptTesterModId} from "../../utils/constants";
import {getLocal, setLocal} from "../../utils/dataFetching";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { useErrorBoundary } from "react-error-boundary";

import { useState, useEffect, useRef, useCallback } from 'react';

const PromptTest = () => {
  const { showBoundary } = useErrorBoundary();

  const [response, setResponse] = useState('');
  const [loadedValue, setLoadedValue] = useState('');
  
  const [prompt, setPrompt] = useState('');
  const [llm, setLLM] = useState('google_gemini_pro');
  const [rag, setRAG] = useState('');
  const [queryPre, setQueryPre] = useState('');
  const [llmPre, setLLMpre] = useState('');

  const [viewMarkdown, setViewMarkdown] = useState(false);

  const promptSessionIdRef = useRef(getLocal('prompt_session_id'));

  const handleToggle = () => {
    setViewMarkdown((viewMarkdown ? false : true));
  };

  const handleLoadChange = (event) => {
    setLoadedValue(event.target.value);
    if (event.target.value) {
      loadPrompt(event.target.value);
    } else {
      setPrompt("");
      setRAG("");
      setQueryPre("");
      setLLMpre("");
    }
    
  };

  const handlePrompt = (event) => {
    setPrompt(event.target.value);
  };
  
  const handleLLM = (event) => {
    setLLM(event.target.value);
  };
  
  const handleRAG = (event) => {
    setRAG(event.target.value);
  };
  
  const handleQueryPre = (event) => {
    setQueryPre(event.target.value);
  };
  
  const handleLLMpre = (event) => {
    setLLMpre(event.target.value);
  };

  const loadPrompt = async (moduleId) => {

    try {
      if (moduleId) {
        const response = await getModule(moduleId);

        if (response && response.prompt) {

          setPrompt(response.prompt);
          setLLM(response.llm_model);
          setRAG((response.rag_id ? response.rag_id : ''));
          setQueryPre((response.queryPrefix ? response.queryPrefix : ''));
          setLLMpre((response.llmResponsePrefix ? response.llmResponsePrefix : ''));
        }
      }
    } catch (error) {
      setResponse('An error occurred while getting Module');
      console.log(error);
    }
  };

  const getSession = useCallback(async () => {

    try {
      if (!promptSessionIdRef.current) {

        // Check if session exists in DB
        const sessionResList = await sessionList();
        sessionResList.data.sessions?.forEach(s => {
          if (s.module === promptTesterModId) {
            promptSessionIdRef.current = s._id;
            setLocal('prompt_session_id', promptSessionIdRef.current);
          }
        });

        // If empty, create one
        if (!promptSessionIdRef.current) {
          const sessionRes = await sessionCreate(promptTesterModId);
          if (sessionRes.data?.session) {
            promptSessionIdRef.current = sessionRes.data.session._id;
            setLocal('prompt_session_id', promptSessionIdRef.current);
          }
        }
      }
    } catch (e) {
      console.error('Error fetching session:', e);
    }

    console.log("promptSessionIdRef");
    console.log(promptSessionIdRef.current);
  }, []);

  useEffect(() => {
    getSession();
  }, [getSession]);

  const [modules, setModules] = useState([]);

  const getModules = useCallback(async () => {
    try {
      let allModules = [];

      const assistantResponse = await getAssistantModules();
      if (assistantResponse?.length) {
        assistantResponse.forEach(m => {
          allModules.push({
            id: m._id,
            title: m.title,
            type: 'Assistant'
          });
        });
      }

      const scenarioResponse = await getScenarioModules();
      if (scenarioResponse?.length) {
        scenarioResponse.forEach(m => {
          allModules.push({
            id: m._id,
            title: m.title,
            type: 'Scenario'
          });
        });
      }

      const summaryResponse = await getSummaryModules();
      if (summaryResponse?.length) {
        summaryResponse.forEach(m => {
          allModules.push({
            id: m._id,
            title: m.title,
            type: 'Summary'
          });
        });
      }

      setModules(allModules);
    } catch (e) {
      return null;
      showBoundary(e);
    }
  }, []);

  useEffect(() => {
    getModules();
  }, [getModules]);

  const processForm = async (event) => {
    event.preventDefault();
    
    const prompt = event.target.elements['prompt-test-prompt'].value;
    const llm = event.target.elements['prompt-test-llm'].value;
    const query = event.target.elements['prompt-test-query'].value;
    const saveContent = event.target.elements['prompt-test-saveContent'].checked;
    const ragId = event.target.elements['prompt-test-ragId'].value;
    const queryPrefix = event.target.elements['prompt-test-queryPrefix'].value;
    const llmResponsePrefix = event.target.elements['prompt-test-llmResponsePrefix'].value;
    const contextLimitCount = event.target.elements['prompt-test-contextLimit-count'].value;

    try {
      setResponse('Please wait...');

      const response = await getLLMResponse(promptSessionIdRef.current, promptTesterModId, query, prompt, ragId, llm, queryPrefix, llmResponsePrefix, saveContent, {type: 'count', value: contextLimitCount});
      
      if (response && response.request && response.request.response) {
        const responseJson = JSON.parse(response.request.response);
        
        console.log(responseJson);


        if (responseJson && responseJson.content) {
          let summary = responseJson.content;

          setResponse(summary);
        } else {
          setResponse('A malformed LLM response was received');
        }
      } else {
        setResponse('A malformed LLM response was received');
      }
    } catch (error) {
      setResponse('An error occurred while getting LLM Response');
      console.log(error);
    }
  };

  return (
    <div className="prompt-test-container">
      <h1>Prompt Test</h1>
      <div className="prompt-test-form-container">
        <form className="prompt-test-form" onSubmit={processForm}>
        <div className="form-field">
            <label htmlFor="prompt-load">Load from API:</label>
            <select id="prompt-load" value={loadedValue} onChange={handleLoadChange}>
              <option value="">Select</option>
              {modules && modules.map(m => {
                return (
                  <option key={m.id} value={m.id}>{m.type} - {m.title}</option>
                );
              })}
            </select>
          </div>
          <div className="form-field">
            <hr />
          </div>
          <div className="form-field">
            <label htmlFor="prompt-test-prompt">Prompt:</label>
            <textarea id="prompt-test-prompt" value={prompt} onChange={handlePrompt}></textarea>
          </div>
          <div className="form-field">
            <label htmlFor="prompt-test-llm">LLM:</label>
            <select id="prompt-test-llm" value={llm} onChange={handleLLM}>
              <option value="google_gemini_pro">google_gemini_pro</option>
              <option value="anthropic_claude_3_sonnet">anthropic_claude_3_sonnet</option>
              <option value="fireworks_mixtral">fireworks_mixtral</option>
              <option value="openai_gpt_3_5_turbo">openai_gpt_3_5_turbo</option>
              <option value="openai_gpt_4o">openai_gpt_4o</option>
            </select>
          </div>
          <div className="form-field">
            <label htmlFor="prompt-test-query">Query:</label>
            <input type="text" id="prompt-test-query" />
          </div>
          <div className="form-field">
            <label htmlFor="prompt-test-saveContent">Save to DB:</label>
            <input type="checkbox" id="prompt-test-saveContent" defaultChecked={true} />
          </div>
          <div className="form-field">
            <label htmlFor="prompt-test-ragId">RAG ID:</label>
            <input type="text" id="prompt-test-ragId" value={rag} onChange={handleRAG} />
          </div>
          <div className="form-field">
            <label htmlFor="prompt-test-queryPrefix">Query Prefix:</label>
            <input type="text" id="prompt-test-queryPrefix" value={queryPre} onChange={handleQueryPre} />
          </div>
          <div className="form-field">
            <label htmlFor="prompt-test-llmResponsePrefix">LLM Response Prefix:</label>
            <input type="text" id="prompt-test-llmResponsePrefix" value={llmPre} onChange={handleLLMpre} />
          </div>
          <div className="form-field">
            <label htmlFor="prompt-test-contextLimit-count">Context Limit Count</label>
            <input type="number" id="prompt-test-contextLimit-count" defaultValue="50" />
          </div>
          <div className="form-actions">
            <button type="submit">Submit</button>
          </div>
        </form>
        <div className="prompt-test-response">
          <div>Response: (<button onClick={handleToggle}>Toggle view</button>)</div>
          {viewMarkdown ? (
              <MarkdownPreview source={response} style={{ padding: 16 }} />
          ) : (
            <div className="prompt-test-response-scrollable">{response}</div>
          )}
           {/* <div className="prompt-test-response-scrollable"><pre><code>{response}</code></pre></div> */ }
          {/* <div className="prompt-test-response-scrollable">{response}</div> */}
        </div>
      </div>
    </div>
  );
};

export default PromptTest;
